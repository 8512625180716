"use client";
import { ErrorFallback } from "services/error/ErrorBoundary/ErrorFallback";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <html lang="en">
      <head>
        <title>Homesafe Connect</title>
        <meta name="description" content="Homesafe Connect" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="assets/app_icon.png" />
      </head>
      <body>
        <ErrorFallback error={error} resetErrorBoundary={reset} />
      </body>
    </html>
  );
}
